import Childcomponent from './searchCn';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import HistoryRecord from '../arcreatecn/history/historyRecord';
import ApprovalHistory from '../arcreatecn/history/approvalHistory';
import UploadDocument from '../arcreatecn/history/uploadDocument';
import sendForApproval from '../arcreatecn/history/sendForApproval';
import store from '../../../../store';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'arcn',
  components: {
    DatePicker,
    ModelSelect,
    BasicSelect,
    Childcomponent,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval
  },
  data() {
    return {
      unsubscribe: null,
      cnHdrId: null,
      rowItem: null,
      showHistoryBtn: false,
      showUserModal: false,
      historyType: null,
      showHistory: false,
      selectLegalEntity: {
        text: null,
        value: null
      }, 
      selectAppListEntity: {
        text: null,
        value: null
      },      
      leList: [],
      appList: [],
      loader: false,
      selectOuName: {
        text: null,
        value: null
      },
      ouList: [],
      custName: null,
      custNum: null,
      TrxNum: null,
      trxNum: null,
      fromDate: null,
      toDate: null,
      previewFlag: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      data: [],
      fields: [
        {
          key: 'select'
        },
        {
          key: 'OU_NAME',
          label: 'Ou Name'
        },
        {
          key: 'CUSTOMER_NAME',
          label: 'Customer_Name'
        },
        {
          key: 'TRX_DATE',
          label: 'Trx_date'
        },
        {
          key: 'TRX_NUMBER',
          label: 'Trx_Number'
        },
        {
          key: 'INV_AMOUNT',
          label: 'Invoice_Amt'
        },
        {
          key: 'APP_NUMBER',
          label: 'Invoice Number'
        },
        {
          key: 'APPLIED_AMOUNT',
          label: 'Invoice Amount'
        },
        {
            key: 'APPROVAL_STATUS',
            label: 'Approval Status'
        },
        {
            key: 'EBS_STATUS',
            label: 'Ebs Status'
        },
        {
          key: 'ERROR_MSG',
          label: 'ERROR_MSG'
      },
        {
          key: 'ORG_ID',
          class: 'd-none'
        },
        {
          key: 'CUSTOMER_TRX_ID',
          class: 'd-none'
        },
        {
          key: 'LEGAL_ENTITY_ID',
          class: 'd-none'
        },
        {
          key: 'PAYMENT_SCHEDULE_ID',
          class: 'd-none'
        },
        {
          key: 'CUSTOMER_ID',
          class: 'd-none'
        },
        {
          key: 'CN_HDR_ID',
          class: 'd-none'
        }
      ]
    };
  },
  validations: {
    selectLegalEntity: {
      value: {
        required
      }
    }
  },
  mounted() {
    this.getOracleLeList();
    this.getWorkflowstatusList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowItem = null;
          this.showUserModal = true;
        }
      }
    });
  },
  methods: {
    rowSelected(item) {
      this.showUserModal = true;
      this.rowItem = item;
      this.previewFlag = true;
    },
    resetModal() {
      this.previewFlag = false;
    },
    getOracleLeList() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;

      this.$store.dispatch('shared/getCommonVSetData', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data.data;
          const leagalEntity = results.map(type => {
            return {
              value: type.org_id,
              text: type.value_code,
            };
          });
          this.leList = leagalEntity;
          this.selectedLegalEntity =
            leagalEntity && leagalEntity.length === 1
              ? leagalEntity[0]
              : this.selectedLegalEntity;
        }
      });
    },
    getOracleOuList(leId) {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.OU_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.le_id = leId;
      this.$store.dispatch('shared/getCommonVSetData', payload).then(response => {
        const results = response.data.data.data;
        this.ouList = results.map(item => {
          return {
            value: item.org_id,
            text: item.value_code
          };
        });

      });

    },
    getOuByLegalId(details) {
      this.selectLegalEntity = details;
      this.getOracleOuList(this.selectLegalEntity.value);
    },
    getSavedInvoiceList() {
      if (this.selectLegalEntity.value === null) {
        alert('Please select the legal entity !');
        return;
      }
      if (this.selectOuName.value === null) {
        alert('Please select the operating unit first !');
        return;
      }
      this.$v.$touch();
      if (!this.$v.$invalid) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectLegalEntity.value,
        ou_id: this.selectOuName.value,
        cust_no: this.custNum,
        cust_name: this.custName,
        trx_num: this.trxNum,
        from_date: this.fromDate,
        to_date: this.toDate,
        app_status: this.selectAppListEntity.value,
      };
      this.loader = true;
      this.$store
        .dispatch('oracleApi/getSavedInvoiceList', payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    selectedAppId(id) {
      this.cnHdrId = id;
      this.showHistoryBtn = true;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },

    getWorkflowstatusList() {
      const payload = {
        valueSetName: 'APPROVAL_STATUS',
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;

      this.$store.dispatch('shared/getCommonVSetData', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data.page;
          this.appList = results.map(type => {
            return {
              value: type.value_code,
              text: type.value_code,
            };
          });
        }
      });
    },

    clearFilters() {
    this.selectLegalEntity = {
      text: null,
      value: null
    },
    this.ouList = [];
    this.custNum = null;
    this.trxNum = null;
    this.fromDate = null;
    this.toDate = null;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
