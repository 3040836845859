import store from '../../../../../../store';
export default {
  name: 'approvalHistory',
  props: ["cnHdrId"],
  data() {
    return {
      wfEventId: null,
      loader: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      eventIdFromNotification: null,
      historyList: [],
      historyFields: [
        {
          key: 'wf_inprocess_hdr_id',
          label: 'Aprroval Batch'
        },
        {
          key: 'approver_name'
        },
        { key: 'approver_designation', label: 'Designation' },
        {
          key: 'action_date'
        },
        {
          key: 'initiation_date'
        },
        {
          key: 'seq_order'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'information_only',
          lable: 'Info Only'
        },
        {
          key: 'version'
        },
        {
          key: 'notify'
        }
      ],
      historyRemList: [],
      reminderFileds: [
        {
          key: 'approver_email_id',
          label: 'Approver Email'
        },
        {
          key: 'approver_name'
        },
        {
          key: 'notified_date'
        },
        {
          key: 'notify_by'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('eventIdFromNotification', eventIdFromNotification => {
      this.eventIdFromNotification = eventIdFromNotification;
    });
    this.approvalHistoryDtl();
    this.getHistoryReminder();
  },
  methods: {
    approvalHistoryDtl() {
      if(store.state.shared.menuId){
        this.getEventId();
      }
      else{
        this.getHistory();
      }
    },
    getHistory(eventId) {
      const payload = {
        eventId: eventId ? eventId : this.eventIdFromNotification,
        nfaHdrId: this.cnHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getApprovalList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.historyList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHistoryReminder() {
      const payload = {
        menuId: store.state.shared.menuId,
        recordId: this.cnHdrId
      };
      this.$store
        .dispatch('intWorkflow/getReminderHitsory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.historyRemList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    sendNotification(item) {
      const payload = {
        approver_emp_id: item.approver_employee_id,
        comm_type_vset: 'SENDGRID',
        event_id: this.wfEventId ? this.wfEventId : this.eventIdFromNotification,
        record_id: this.cnHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('workflow/sendNotification', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
            this.getHistoryReminder();
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          if (response.status === 200) {
            this.wfEventId = response.data.data;
            this.getHistory(this.wfEventId);
          }
        });
    }
  },
  beforeDestroy() {
    this.eventBus.$off('eventIdFromNotification');
  }
};
