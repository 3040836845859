import { ModelSelect, BasicSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../store';
import { format } from 'date-fns';
import Consult from './consult';

export default {
  name: 'searchCN',
  components: {
    DatePicker,
    ModelSelect,
    BasicSelect,
    Consult
  },
  props: [
    'rowObject',
    'propsSelectedGridData',
    'eventId',
    'wf_inprocess_dtl_id',
    'previewFlag'
  ],
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      editMode: false,
      notificationSummary: false,
      toDate: null,
      fromDate: null,
      responseMsg: null,
      trxNum: null,
      sLeId: 0,
      sOuId: 0,
      searchText: null,
      selectLegalEntity: {
        text: null,
        value: null
      },
      selectInvClass: {
        text: null,
        value: null
      },
      selectInvType: {
        text: null,
        value: null
      },
      wfStatus: null,
      isApprovedByCurrrentUser: false,
      leList: [],
      loader: false,
      selectOuName: {
        text: null,
        value: null
      },
      ouList: [],
      urlSummaryData: null,
      cnHdrId: 0,
      custName: null,
      custNum: null,
      invoiceTypeList: [],
      cnCategoryList: [],
      currentPage: 1,
      totalRows: null,
      showConsultModal: false,
      notificationComments: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      data: [],
      fields: [
        {
          key: 'select'
        },
        {
          key: 'OU_NAME',
          label: 'OU Name'
        },
        {
          key: 'CN_HDR_ID',
          class: 'd-none'
        },
        {
          key: 'CUSTOMER_NUMBER',
          label: 'Customer Number'
        },
        {
          key: 'CUSTOMER_NAME',
          label: 'Customer Name'
        },
        {
          key: 'TRX_DATE',
          label: 'Trx date'
        },
        {
          key: 'TRX_NUMBER',
          label: 'Trx Number'
        },
        {
          key: 'INV_AMOUNT',
          label: 'Invoice Amt'
        },
        {
          key: 'DUE_AMOUNT',
          label: 'Due Amount'
        },
        {
          key: 'INV_CLASS',
          label: 'Invoice Class'
        },
        {
          key: 'INV_TYPE',
          label: 'Invoice Type'
        },
        {
          key: 'CN_NUM',
          label: 'Invoice Num'
        },
        {
          key: 'APPLIED_AMOUNT',
          label: 'Invoice Amount'
        },
        {
          key: 'INVOICE_DATE',
          label: 'Invoice Date'
        },
        {
          key: 'GL_DATE',
          label: 'Gl Date'
        },
        {
          key: 'APP_TAX_FLAG',
          label: 'Tax Flag'
        },
        {
          key: 'TAX_CATEGORY',
          label: 'Tax Category'
        },
        {
          key: 'INVOICE_DESC',
          class: ''
        },
        {
          key: 'CN_CATEGORY',
          label: 'CN Category'
        },
        {
          key: 'HQ_NAME',
          class: ''
        },
        {
          key: 'SERVICE_MONTH',
          class: ''
        },
        {
          key: 'GST_NUM',
          label: 'Invoice GSTN'
        },
        {
          key: 'ATTRIBUTE1',
          label: 'GSTN On Site'
        },
        {
          key: 'PARTY_SITE_NUM',
          class: ''
        },
        {
          key: 'TRX_NOTE',
          class: '',
          label: 'NOTE'
        },
        {
          key: 'APPROVAL_STATUS'
        },
        {
          key: 'ORG_ID',
          class: 'd-none'
        },
        {
          key: 'CUSTOMER_TRX_ID',
          class: 'd-none'
        },
        {
          key: 'LEGAL_ENTITY_ID',
          class: 'd-none'
        },
        {
          key: 'PAYMENT_SCHEDULE_ID',
          class: 'd-none'
        },
        {
          key: 'CUSTOMER_ID',
          class: 'd-none'
        },
        {
          key: 'TAX_FLAG',
          class: 'd-none'
        },
        {
          key: 'TAX_RATE',
          class: 'd-none'
        }
      ],
      approvalHistoryList: [],
      invClassList: [],
      invTypeList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        }
      ],
      trxIndex: null,
      valueSetFields: [
        {
          key: 'value_meaning'
        },
        {
          key: 'value_code'
        }
      ]
    };
  },
  mounted() {
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.saveCNApplication();
        }
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.getOracleLeList();
    this.getInvClassList();
    this.getCnCategoryList();
    if (this.rowObject || this.propsSelectedGridData) {
      this.fillRecordDetail();
    }
  },
  methods: {
    clearFilters() {
      this.toDate = null;
      this.fromDate = null;
    },
    fillRecordDetail() {
      if (this.rowObject !== null || this.propsSelectedGridData !== null) {
        const dataFromGrid = this.rowObject
          ? [this.rowObject]
          : this.propsSelectedGridData;
        this.data = dataFromGrid.map(item => {
          return {
            OU_NAME: item.OU_NAME,
            CUSTOMER_NAME: item.CUSTOMER_NAME,
            TRX_DATE: item.TRX_DATE,
            TRX_NUMBER: item.TRX_NUMBER,
            DUE_DATE: item.DUE_DATE,
            INV_AMOUNT: item.INV_AMOUNT,
            DUE_AMOUNT: item.DUE_AMOUNT,
            CN_NUM: item.APP_NUMBER,
            APPLIED_AMOUNT: item.APPLIED_AMOUNT,
            ORG_ID: item.ORG_ID,
            CUSTOMER_TRX_ID: item.CUSTOMER_TRX_ID,
            LEGAL_ENTITY_ID: item.LEGAL_ENTITY_ID,
            PAYMENT_SCHEDULE_ID: item.PAYMENT_SCHEDULE_ID,
            CUSTOMER_ID: item.CUSTOMER_ID,
            APPROVAL_STATUS: item.APPROVAL_STATUS,
            CN_HDR_ID: item.CN_HDR_ID,
            GL_DATE: item.GL_DATE,
            TRX_TYPE: item.TRX_TYPE,
            INVOICE_DATE: item.INVOICE_DATE
          };
        });
        if (
          this.$route.name === 'notificationSummary' ||
          this.$route.name === 'dashboard' ||
          this.$route.name === 'summary'
        ) {
          this.notificationSummary = true;
          this.cnHdrId = this.data[0].CN_HDR_ID;
          this.getApprovalHistory(this.cnHdrId);
        }
      } else {
        return (this.editMode = true);
      }
    },
    getOracleLeList() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.value_code
              };
            });
            this.leList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },
    getOracleOuList(leId) {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.OU_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.le_id = leId;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          const results = response.data.data.data;
          this.ouList = results.map(item => {
            return {
              value: item.org_id,
              text: item.value_code
            };
          });
        });
    },
    getOuByLegalId(details) {
      this.selectLegalEntity = details;
      this.getOracleOuList(this.selectLegalEntity.value);
    },
    getInvoiceDetailsList() {
      if (this.selectLegalEntity.value === null) {
        alert('Please select the legal entity !');
        return;
      }
      if (this.selectOuName.value === null) {
        alert('Please select the operating unit first !');
        return;
      }
      if (this.selectInvClass.value === null) {
        alert('Please select the invoice class first !');
        return;
      }
      if (this.custNum === null) {
        alert('Please enter customer first !');
        return;
      }
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectLegalEntity.value,
        ou_id: this.selectOuName.value,
        inv_class: this.selectInvClass.text,
        cust_no: this.custNum,
        trx_num: this.trxNum,
        from_date: this.fromDate,
        to_date: this.toDate
      };
      this.loader = true;
      this.showAlert = false;
      this.$store
        .dispatch('oracleApi/getInvoiceList', payload)
        .then(response => {
          this.loader = false;
          if (response.data.data === null) {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = 'No Record Found.';
          }
          const result = response.data.data;
          this.data = result.map(key => {
            key.CN_CATEGORY = null;
            key.INV_CLASS =
              key.INV_CLASS === null ? this.selectInvClass.text : key.INV_CLASS;
            return key;
          });
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveCNApplication() {
      const filteredAppDetail = this.data.filter(
        cnTblDetails => cnTblDetails.select == true
      );
      const details = filteredAppDetail.map(applicationDetails => {
        return {
          PAYMENT_SCHEDULE_ID: applicationDetails.PAYMENT_SCHEDULE_ID,
          CUSTOMER_TRX_ID: applicationDetails.CUSTOMER_TRX_ID,
          TRX_NUMBER: applicationDetails.TRX_NUMBER,
          CUSTOMER_NUMBER: applicationDetails.CUSTOMER_NUMBER,
          APPLIED_AMOUNT: applicationDetails.APPLIED_AMOUNT,
          ORG_ID: applicationDetails.ORG_ID,
          TRX_DATE: format(
            new Date(applicationDetails.TRX_DATE),
            'dd-MMM-yyyy'
          ),
          CUSTOMER_ID: applicationDetails.CUSTOMER_ID,
          DUE_AMOUNT: applicationDetails.DUE_AMOUNT,
          INV_AMOUNT: applicationDetails.INV_AMOUNT,
          CUSTOMER_NAME: applicationDetails.CUSTOMER_NAME,
          LEGAL_ENTITY_ID: applicationDetails.LEGAL_ENTITY_ID,
          CN_NUM: applicationDetails.CN_NUM,
          OU_NAME: applicationDetails.OU_NAME,
          INV_TYPE: applicationDetails.INV_TYPE,
          GL_DATE: format(new Date(applicationDetails.GL_DATE), 'dd-MMM-yyyy'),
          INVOICE_DATE: format(
            new Date(applicationDetails.INVOICE_DATE),
            'dd-MMM-yyyy'
          ),
          INV_CLASS: applicationDetails.INV_CLASS,
          INVOICE_DESC: applicationDetails.INVOICE_DESC,
          TAX_FLAG: applicationDetails.TAX_FLAG,
          TAX_CATEGORY: applicationDetails.TAX_CATEGORY,
          APP_TAX_FLAG: applicationDetails.APP_TAX_FLAG,
          TAX_RATE: applicationDetails.TAX_RATE,
          TRX_NOTE: applicationDetails.TRX_NOTE,
          SERVICE_MONTH: applicationDetails.SERVICE_MONTH,
          HQ_NAME: applicationDetails.HQ_NAME,
          GST_NUM: applicationDetails.GST_NUM,
          CN_CATEGORY: applicationDetails.CN_CATEGORY
        };
      });
      const payload = {
        application_details: details ? details : null
      };
      this.loader = true;
      this.$store
        .dispatch('oracleApi/saveInvoiceApplication', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          //alert(response.status);
          if (response.status === 201) {
            //alert(response.data.message);
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = true;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getApprovalHistory(cnHdrId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: cnHdrId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    (user.approver_employee_id === this.userInfo.employee_id &&
                      user.approval_status === 'APPROVED') ||
                    user.approval_status === 'AUTO-APPROVED'
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    showHideConsult(flag) {
      this.showConsultModal = flag;
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wf_inprocess_dtl_id
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(this.cnHdrId);
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveScrapRecord() {
      const promt = confirm('Scrap Record ?');
      if (promt) {
        this.scrapRecord = 'scrap';
        this.addEditIntWorkflow();
      }
    },
    showHidePrint(flag) {
      this.showPrintModal = flag;
    },
    disabledDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    async printIntWorkflow() {
      if (this.selectedGridData.nfa_sub_cat === 'Waiver') {
        this.nfaSubCategory;
      }
      await this.$htmlToPaper('print-workflow', {
        windowTitle: this.wfNumber
      });
    },
    getWfDetail(nfaHdrId) {
      this.cnHdrId = nfaHdrId;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getWfDetailsById', nfaHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.selectedGridData = response.data.data;
            this.setHeaderData(this.selectedGridData);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventId() {
      if (store.state.shared.menuId) {
        this.$store
          .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
          .then(response => {
            if (response.status === 200) {
              this.wfEventId = response.data.data;
            }
          });
      }
    },
    getInvClassList() {
      const payload = {
        valueSetName: 'ORACLE_AR_TRX_CLASS',
        dependentVariable: {}
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.page;
            const invClassConst = results.map(type => {
              return {
                value: type.value_set_dtl_id,
                text: type.value_code
              };
            });
            this.invClassList = invClassConst;
            this.selectInvClass =
              invClassConst && invClassConst.length === 1
                ? invClassConst[0]
                : this.selectInvClass;
          }
        });
    },
    getTrxTypeBYClass(details) {
      this.selectInvClass = details;
      if (this.selectOuName.value === null) {
        alert('Please select the operating unit first !');
        return false;
      }
      const payload = {
        valueSetName: 'AR_TRX_TYPE_LIST',
        dependentVariable: {
          search_key: this.searchText
        }
      };
      payload.dependentVariable.org_id = this.selectOuName.value;
      payload.dependentVariable.parent_value_set_id = this.selectInvClass.value;
      // alert(this.selectInvClass.value);
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            this.invoiceTypeList = results;
          }
        });
    },

    getCnCategoryList() {
      // this.selectInvClass = details;
      const payload = {
        valueSetName: 'CN_CATEGORY',
        dependentVariable: {
          search_key: this.searchText
        }
      };
      // payload.dependentVariable.parent_value_set_id = this.selectInvClass.value;
      // alert(this.selectInvClass.value);
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.page;
            this.cnCategoryList = results;
          }
        });
    },
    setTrxIndex(index) {
      this.trxIndex = index;
    },
    selectInvoiceType(item) {
      this.$bvModal.hide('invoice-type');
      this.data[this.trxIndex].INV_TYPE = item.value_meaning;
    },
    selectCnCategory(item) {
      this.$bvModal.hide('cn-category');
      this.data[this.trxIndex].CN_CATEGORY = item.value_meaning;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
