import store from '../../../../../../store';
export default {
  name: 'sendForApproval',
  props: ["cnHdrId"],
  data() {
    return {
      eventId: null,
      loader: false,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      historyList: [],
      historyFields: [
        {
          key: 'approver_name',
          label: 'Approver'
        },
        {
          key: 'approver_designation',
          label: 'Designation'
        },
        {
          key: 'action_date'
        },
        {
          key: 'initiation_date',
          label: 'Init Date'
        },
        {
          key: 'seq_order'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'information_only',
          class: 'd-none'
        },
        {
          key: 'add'
        }
      ]
    };
  },
  mounted() {
    this.getEventId();
  },
  methods: {
    getEventId() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.eventId = response.data.data;
            this.getHistory(response.data.data);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHistory(eventId) {
      this.loader = true;
      const payload = {
        event_id: eventId,
        record_id: this.cnHdrId
      };
      this.$store
        .dispatch('intWorkflow/processWorkflow', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.historyList = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.historyList.push({
        comments: null,
        seq_order: null,
        wf_inprocess_hdr_id: null,
        information_only: false,
        delegated_employee_designation: null,
        approver_employee_id: null,
        wf_inprocess_dtl_id: null,
        approver_employee_num: null,
        approver_designation: null,
        delegated_employee_id: null,
        delegated_employee_num: null,
        approver_name: null,
        approval_status: null,
        initiation_date: null,
        action_date: null,
        delegated_employee_name: null
      });
    },
    removeRow(index) {
      this.historyList.splice(index, 1);
    },
    sendForApproval() {
      const filterData = this.historyList;
      if (filterData.length > 0) {
        const approverDetails = filterData.map(data => {
          return {
            approver_employee_id: data.approver_employee_id,
            information_only: data.information_only,
            seq_order: data.seq_order,
            wf_inprocess_dtl_id: data.wf_inprocess_dtl_id,
            wf_inprocess_hdr_id: data.wf_inprocess_hdr_id
          };
        });
        if (approverDetails && approverDetails.length > 0) {
          const payload = {
            approval_details: approverDetails,
            event_id: this.eventId,
            record_id: this.cnHdrId
          };
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/getWrkflowApproval', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.$emit('updateList');
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
    },
    close() {}
  }
};
