import store from "../../../../../../store";
export default {
  name: 'historyRecord',
  props: ["cnHdrId"],
  data() {
    return {
      loader: false,
      history: {
        created_by: null,
        creation_date: null,
        last_updated_by: null,
        last_updated_date: null
      }
    };
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      const payload = {
        historyType: 'history',
        menu_id: store.state.shared.menuId,
        record_id: this.cnHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getHistory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.history = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
