import store from '../../../../../../store';
import URL_UTILITY from '@/app/utility/url.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'uploadDocument',
  props: ['wfHdrId', 'openkmAuthToken', 'selectedGridData'],
  watch: {
    currentPage: function() {
      this.getUploadedDocDtl();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUploadedDocDtl();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      progressBarMaxValue: 100,
      prgressbarValue: 0,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      selectedFilesArr: [],
      loader: false,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      uploadDocList: [],
      selectedObjectData: {
        le_id: null,
        le_name: null,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      uploadDocFields: [
        {
          key: 'file_name'
        },
        {
          key: 'leName'
        },
        {
          key: 'categoryName',
          label: 'Category'
        },
        {
          key: 'subcategoryName',
          label: 'Sub-Cat'
        },
        {
          key: 'comments'
        },
        {
          key: 'version'
        },
        {
          key: 'file_size'
        },
        {
          key: 'delete'
        }
      ]
    };
  },
  mounted() {
    this.selectedObjectData = this.selectedGridData;
    this.getUploadedDocDtl();
  },
  methods: {
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: store.state.shared.menuId,
        sub_menu_id: 0,
        record_id: this.wfHdrId
      };
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map(data => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData.le_id,
                  leName: this.selectedObjectData.le_name,
                  categoryCode: this.selectedObjectData.nfa_cat_vset,
                  categoryName: this.selectedObjectData.nfa_cat,
                  subcategoryCode: this.selectedObjectData.nfa_sub_cat_vset,
                  subcategoryName: this.selectedObjectData.nfa_sub_cat,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    uploadFile() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('token', this.openkmAuthToken);
          formData.append('category', data.categoryCode);
          formData.append('sub_category', data.subcategoryCode);
          formData.append('comment', data.comments);
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', this.$store.state.shared.menuId);
          formData.append('sub_menu_id', 0);
          formData.append('le_id', data.leId);
          formData.append('record_id', this.wfHdrId);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadOpenKmDoc', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.getUploadedDocDtl();
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    browseFile() {
      this.$refs.file.click();
    },
    selectFile() {
      this.selectedFilesArr.push(this.$refs.file.files);
      this.uploadDocList.push({
        fileRef: this.$refs.file.files,
        file_name: this.$refs.file.files[0].name,
        leId: this.selectedObjectData.le_id,
        leName: this.selectedObjectData.le_name,
        categoryCode: this.selectedObjectData.nfa_cat_vset,
        categoryName: this.selectedObjectData.nfa_cat,
        subcategoryCode: this.selectedObjectData.nfa_sub_cat_vset,
        subcategoryName: this.selectedObjectData.nfa_sub_cat,
        comments: '',
        version: 0,
        file_size: this.formatBytes(this.$refs.file.files[0].size),
        action: null,
        docDetailId: 0
      });
    },
    deleteRow(index, docDetailId) {
      const payload = {
        docDetailId: docDetailId,
        token: this.openkmAuthToken
      };
      if (docDetailId !== 0) {
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/delteOpenKmDoc', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
              this.uploadDocList.splice(index, 1);
            } else {
              this.isSuccess = false;
              this.respMessage =
                response.response.data.message || 'Something went wrong';
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.uploadDocList.splice(index, 1);
      }
    },
    logoutFromOpen() {
      this.loader = true;
      const payload = {
        token: this.openkmAuthToken
      };
      this.$store
        .dispatch('intWorkflow/logoutOpenKm', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('closeOpenKmModal');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
};
